import {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {
  getOpcacheSettingsWithTemplate,
  updateOpcacheSettings,
} from '../../../../servers-list/core/_requests'
import {ServerServicesSetting} from '../../../../servers-list/core/_models'
import * as Yup from 'yup'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'

export function Opcache() {
  const {serverId} = useParams()

  const [basicSettings, setBasicSettings] = useState<ServerServicesSetting[]>()
  const [editingIndex, setEditingIndex] = useState<number | null>(null)
  const [editedSettings, setEditedSettings] = useState<string>('')
  const [editedSettingTemplate, setEditedSettingTemplate] = useState<string>('')
  const [validationError, setValidationError] = useState<string | null>(null)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  useEffect(() => {
    getOpcacheSettingsWithTemplate(serverId ?? '').then((resp) => {
      setBasicSettings(resp)
    })
  }, [serverId])

  const handleEditClick = (index: number) => {
    setValidationError(null)
    setEditedSettingTemplate(basicSettings![index].template)
    if (index === editingIndex) {
      setEditingIndex(null)
    } else {
      setEditingIndex(index)
      setEditedSettings(
        Object.keys(basicSettings![index].settings)
          .map((key) =>
            basicSettings![index].template
              .replace('{{name}}', key)
              .replace('{{value}}', basicSettings![index].settings[key])
          )
          .join('\n')
      )
    }
  }

  const handleSaveClick = async (index: number) => {
    if (index === null) return

    const validationSchema = Yup.object().shape({
      editedSettings: Yup.string().test('isValid', 'Invalid format', (value) => {
        if (!value) return true
        const lines = value.split('\n')
        return lines.every((line) => {
          return new RegExp(
            editedSettingTemplate.replace('{{name}}', '.+').replace('{{value}}', '.+')
          ).test(line.trim())
        })
      }),
    })

    validationSchema
      .validate({editedSettings})
      .then(async () => {
        setIsSaving(true)

        const success = await updateOpcacheSettings(
          serverId ?? '',
          basicSettings![index].service,
          basicSettings![index].version,
          editedSettings
        )

        if (success) {
          notifySuccess('Settings updated!')
          getOpcacheSettingsWithTemplate(serverId ?? '').then((resp) => {
            setBasicSettings(resp)
          })

          setEditingIndex(null)
        } else {
          notifyError('Unable to save the settings!')
        }

        setIsSaving(false)
      })
      .catch((error) => {
        setValidationError(error.message)
        setIsSaving(false)
      })
  }

  return (
    <div className='card mb-5 mb-xl-10' id='dc_opcache_view'>
      <div className='tab-content' id='myTabContent'>
        <div className='tab-pane fade show active' id='basic_settings' role='tabpanel'>
          <div className='card mb-5 mb-xl-5' id='dc_server_settings_view'>
            <div className='card-header cursor-pointer'>
              <div className='card-title'>
                <h3 className='fw-bolder'>Server Services Settings</h3>
              </div>
            </div>

            <div className='card-body'>
              <div className='alert alert-primary d-flex align-items-center p-4 mb-10'>
                <div className='d-flex flex-column'>
                  <span>
                    Please restart the service to get the changed settings into effect. You can
                    restart the services from{' '}
                    <u>
                      <Link to={`/server/${serverId}/services`}>Services</Link>
                    </u>{' '}
                    page.
                  </span>
                </div>
              </div>
              {basicSettings &&
                basicSettings.map((item, index) => {
                  if (!item.settings) return

                  const isEditing = index === editingIndex

                  return (
                    <div key={index} className='rounded border p-6 bg-light-primary bg-opacity-5'>
                      <div className='d-flex align-items-center justify-content-between mb-7'>
                        <div className='fs-4 fw-bold'>
                          {item.service}-{item.version}
                        </div>
                        {!isEditing && (
                          <button
                            className='btn btn-light-primary'
                            onClick={() => handleEditClick(index)}
                          >
                            <i className='fas fa-pen me-2'></i>Edit Settings
                          </button>
                        )}
                      </div>

                      {isEditing ? (
                        <div className='row'>
                          <div className='col-lg-12'>
                            <textarea
                              rows={10}
                              className='form-control mb-3'
                              value={editedSettings}
                              spellCheck={false}
                              onChange={(e) => {
                                setEditedSettings(e.target.value)
                                setValidationError(null)
                              }}
                            />
                            {validationError && (
                              <div className='alert alert-danger d-flex align-items-center p-3 mb-5'>
                                <i className='fas fa-exclamation-triangle me-2'></i>
                                <div>{validationError}</div>
                              </div>
                            )}
                            <div className='d-flex justify-content-end mt-5'>
                              <button
                                className='btn btn-light me-3'
                                onClick={() => handleEditClick(index)}
                                disabled={isSaving}
                              >
                                Cancel
                              </button>
                              <button
                                className='btn btn-primary'
                                onClick={() => handleSaveClick(index)}
                                disabled={isSaving}
                              >
                                {isSaving ? (
                                  <>
                                    <span
                                      className='spinner-border spinner-border-sm me-2'
                                      role='status'
                                      aria-hidden='true'
                                    ></span>
                                    Saving...
                                  </>
                                ) : (
                                  'Save Changes'
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        Object.keys(item.settings).map((key) => (
                          <div key={key} className='d-flex flex-stack'>
                            <div className='input-group input-group-solid'>
                              <span className='input-group-text min-w-300px'>{key}</span>
                              <span className='form-control form-control-solid bg-white'>
                                {item.settings[key]}
                              </span>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  )
                })}
              {(!basicSettings || !basicSettings.length) && (
                <div className='alert alert-danger d-flex align-items-center p-5 mx-8 my-5'>
                  <div className='d-flex flex-column'>
                    <span>No services installed yet!</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Opcache
