import axios, {AxiosResponse} from 'axios'
import {ID, PaginationResponse, Response} from '../../../../../_metronic/helpers'
import {
  ServerDto,
  InstanceOptionsQueryResponse,
  LaunchOptionsQueryResponse,
  Server,
  ServersQueryResponse,
  SSHDetails,
  ServerServicesSetting,
  ServerActionType,
  RazorpayPaymentVerificationDto,
  LaunchServerDto,
  Firewall,
  ScaleServerDto,
  ServerUserType,
  ServerOptimization,
  Optimization,
  Alert,
  CreateAlert,
} from './_models'
import {ApiResponse} from '../../../auth'
import {requestWrapper} from '../../../../utils/RequestWrapper'

const API_URL = process.env.REACT_APP_THEME_API_URL
const SERVER_URL = `${API_URL}/v1/server`

// TODO: add query filter for search bar on BE
// query filter for asc desc columns
const getServers = (query: string): Promise<PaginationResponse<Server[]> | undefined> => {
  return axios
    .get(`${SERVER_URL}?${query}`)
    .then((response: AxiosResponse<Response<PaginationResponse<Server[]>>>) => response.data)
    .then((response: Response<PaginationResponse<Server[]>>) => response.data)
}

const getAllServers = (): Promise<ServersQueryResponse> => {
  return axios.get(`${SERVER_URL}/all`).then((d: AxiosResponse<ServersQueryResponse>) => d.data)
}

const getServerById = (id: ID): Promise<Server | undefined> => {
  return axios
    .get(`${SERVER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Server>>) => response.data)
    .then((response: Response<Server>) => response.data)
}

const getFirewallSetting = (id: ID, port: number): Promise<Firewall | undefined> => {
  return axios
    .get(`${SERVER_URL}/${id}/firewall/?port=${port}`)
    .then((response: AxiosResponse<Response<Firewall>>) => response.data)
    .then((response: Response<Firewall>) => response.data)
}

const updateFirewallSetting = async (
  id: ID,
  firewallSetting: Firewall
): Promise<Firewall | undefined> => {
  const response = await axios.post(`${SERVER_URL}/${id}/firewall/`, firewallSetting)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getServerWithMetadata = (
  id: string
): Promise<
  | {
      server: Server
      logo: string
      description: string
    }
  | undefined
> => {
  return axios
    .get(`${SERVER_URL}/metadata/${id}`)
    .then(
      (
        response: AxiosResponse<
          Response<{
            server: Server
            logo: string
            description: string
          }>
        >
      ) => response.data
    )
    .then(
      (
        response: Response<{
          server: Server
          logo: string
          description: string
        }>
      ) => response.data
    )
}

const createServer = async (server: ServerDto) => {
  const response = await axios.post<ApiResponse>(SERVER_URL, server)
  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const updateServer = async (server: ServerDto) => {
  const response = await axios.patch<ApiResponse>(`${SERVER_URL}/${server.server_id}`, {
    name: server.name,
  })
  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const serverAction = async (
  serverId: ID,
  action: ServerActionType
): Promise<boolean | undefined> => {
  const response = await axios.post(`${SERVER_URL}/${serverId}/action`, {action_type: action})
  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const transferServer = (serverId: ID, email: string): Promise<boolean | undefined> => {
  return axios
    .patch(`${SERVER_URL}/${serverId}/action`, email)
    .then((response: AxiosResponse<boolean>) => response.data)
}

const deleteServer = (serverId: ID): Promise<boolean | undefined> => {
  return axios
    .delete(`${SERVER_URL}/${serverId}/action`)
    .then((response: AxiosResponse<Response<boolean>>) => response.data)
    .then((response: Response<boolean>) => response.data)
}

const getServerMonitoringData = async (serverId: ID) => {
  return requestWrapper(axios.get(`${SERVER_URL}/${serverId}/monitor`))
}

const getMonitService = async (serverId: ID) => {
  return requestWrapper(axios.get(`${SERVER_URL}/${serverId}/service/monit`))
}

const getNewrelicService = async (serverId: ID) => {
  const response = await axios.get(`${SERVER_URL}/${serverId}/service/newrelic`)
  return response.data
}

const getServerServices = async (serverId: ID) => {
  return requestWrapper(axios.get(`${SERVER_URL}/${serverId}/services/`))
}

const getServerServicesStatus = async (serverId: ID) => {
  return requestWrapper(axios.get(`${SERVER_URL}/${serverId}/services/status`))
}

const restartService = async (serverId: ID, service: string, version: string) => {
  const response = await axios.post(`${SERVER_URL}/${serverId}/service/restart`, {service, version})

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const deleteSelectedServers = (serverIds: Array<ID>): Promise<void> => {
  const requests = serverIds.map((id) => axios.delete(`${SERVER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getLaunchOptions = (): Promise<LaunchOptionsQueryResponse> => {
  return axios
    .get(`${SERVER_URL}/launch-option`)
    .then((d: AxiosResponse<LaunchOptionsQueryResponse>) => d.data)
}

const getInstanceOptions = (): Promise<InstanceOptionsQueryResponse> => {
  return axios
    .get(`${SERVER_URL}/instance-option/formatted`)
    .then((d: AxiosResponse<InstanceOptionsQueryResponse>) => d.data)
}

const getScalingOptions = (serverId: ID): Promise<any> => {
  return axios
    .get(`${SERVER_URL}/${serverId}/scaling-options`)
    .then((d: AxiosResponse<any>) => d.data)
}

const getPriceDifference = (serverId: ID, instanceOption: number): Promise<any> => {
  return axios
    .get(`${SERVER_URL}/${serverId}/price-difference?instanceOption=${instanceOption}`)
    .then((d: AxiosResponse<any>) => d.data)
}

const getSSHDetails = (serverId: string, type: ServerUserType): Promise<SSHDetails | undefined> => {
  return axios
    .get(`${SERVER_URL}/server-ssh-auth-detail?serverId=${serverId}&type=${type}`)
    .then((response: AxiosResponse<Response<SSHDetails>>) => response.data)
    .then((response: Response<SSHDetails>) => response.data)
}

const getServerServicesSettingsWithTemplate = (
  serverId: string
): Promise<ServerServicesSetting[] | undefined> => {
  return axios
    .get(`${SERVER_URL}/server-services-setting/template?serverId=${serverId}`)
    .then((response: AxiosResponse<Response<ServerServicesSetting[]>>) => response.data)
    .then((response: Response<ServerServicesSetting[]>) => response.data)
}

const updateServerServiceSettings = async (
  serverId: string,
  service: string,
  version: string,
  setting: string
): Promise<boolean> => {
  const response = await axios.post(`${SERVER_URL}/server-services-setting/settings`, {
    serverId,
    service,
    version,
    setting,
  })

  return response.data.data
}

const getOpcacheSettingsWithTemplate = async (
  serverId: string
): Promise<ServerServicesSetting[] | undefined> => {
  const response = await axios.get(
    `${SERVER_URL}/server-services-setting/template/opcache?serverId=${serverId}`
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const updateOpcacheSettings = async (
  serverId: string,
  service: string,
  version: string,
  setting: string
): Promise<boolean> => {
  const response = await axios.post(`${SERVER_URL}/server-services-setting/settings/opcache`, {
    serverId,
    service,
    version,
    setting,
  })

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const verifyRazorpaySignature = async (
  checkoutId: string,
  verificationData: RazorpayPaymentVerificationDto
): Promise<any> => {
  const response = await axios.post(
    `${API_URL}/v1/payment/checkout/razorpay/verify/${checkoutId}`,
    verificationData
  )

  return response.data.data
}

const launchExternalServer = async (data: LaunchServerDto): Promise<boolean> => {
  const response = await axios.post(`${SERVER_URL}/server-deployment-job/init/external`, data)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const launchInternalServer = async (data: LaunchServerDto): Promise<boolean> => {
  const response = await axios.post(`${SERVER_URL}/server-deployment-job/init/internal`, data)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const scaleExternalServer = async (serverId: ID, data: ScaleServerDto): Promise<boolean> => {
  const response = await axios.post(`${SERVER_URL}/${serverId}/init-scaling/external`, data)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const scaleInternalServer = async (serverId: ID, data: ScaleServerDto): Promise<boolean> => {
  const response = await axios.post(`${SERVER_URL}/${serverId}/init-scaling/internal`, data)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const updateServerSwapState = async (
  serverId: string,
  state: boolean
): Promise<boolean | undefined> => {
  const response = await axios.post(`${SERVER_URL}/${serverId}/optimization/swap/state`, {
    state,
  })

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data
}

const updateMonitService = async (
  serverId: string,
  state: boolean
): Promise<boolean | undefined> => {
  const response = await axios.post(`${SERVER_URL}/${serverId}/service/monit`, {
    state,
  })

  if (response.data.code || response.data.message) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const updateNewrelicService = async (
  serverId: string,
  state: boolean
): Promise<boolean | undefined> => {
  const response = await axios.post(`${SERVER_URL}/${serverId}/service/newrelic`, {
    state,
  })

  if (response.data.code || response.data.message) {
    throw new Error(response.data.message)
  }

  return response.data
}

const updateServerNetworkingState = async (
  serverId: string,
  state: boolean
): Promise<boolean | undefined> => {
  const response = await axios.post(`${SERVER_URL}/${serverId}/optimization/networking/state`, {
    state,
  })

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data
}

const getServerOptimization = (
  serverId: string,
  type: ServerOptimization
): Promise<Optimization | undefined> => {
  return axios
    .get(`${SERVER_URL}/${serverId}/optimization/${type}`)
    .then((response: AxiosResponse<Response<Optimization>>) => response.data)
    .then((response: Response<Optimization>) => response.data)
}

const updateServerOptimizationState = async (
  serverId: string,
  type: ServerOptimization,
  state: boolean
): Promise<boolean> => {
  const response = await axios.post(`${SERVER_URL}/${serverId}/optimization/${type}/state`, {
    state,
  })

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const getAlerts = async (serverId: string): Promise<Alert[]> => {
  const response = await axios.get(`${SERVER_URL}/${serverId}/alert/`)

  console.log(response)
  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const createAlert = async (serverId: string, alert: CreateAlert): Promise<Alert[]> => {
  const response = await axios.post(`${SERVER_URL}/${serverId}/alert/`, alert)

  if (response.data.code || response.data.message) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const updateAlert = async (
  serverId: string,
  alertId: string,
  alert: CreateAlert
): Promise<Alert[]> => {
  const response = await axios.patch(`${SERVER_URL}/${serverId}/alert/${alertId}`, alert)

  if (response.data.code || response.data.message) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const deleteAlert = async (serverId: string, alertId: string): Promise<Alert[]> => {
  const response = await axios.delete(`${SERVER_URL}/${serverId}/alert/${alertId}`)

  if (response.data.code || response.data.message) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

export {
  getServers,
  getAllServers,
  getServerById,
  getFirewallSetting,
  updateFirewallSetting,
  getServerWithMetadata,
  getMonitService,
  getNewrelicService,
  getServerMonitoringData,
  getServerServices,
  getServerServicesStatus,
  getOpcacheSettingsWithTemplate,
  updateOpcacheSettings,
  restartService,
  createServer,
  updateServer,
  serverAction,
  deleteServer,
  deleteSelectedServers,
  getLaunchOptions,
  getInstanceOptions,
  getScalingOptions,
  getPriceDifference,
  getSSHDetails,
  getServerServicesSettingsWithTemplate,
  updateServerServiceSettings,
  verifyRazorpaySignature,
  transferServer,
  launchExternalServer,
  launchInternalServer,
  scaleExternalServer,
  scaleInternalServer,
  updateServerSwapState,
  getServerOptimization,
  updateServerOptimizationState,
  updateServerNetworkingState,
  updateMonitService,
  updateNewrelicService,
  getAlerts,
  createAlert,
  updateAlert,
  deleteAlert,
}
