import {Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import Tabs from '../../../../../utils/Tabs'
import General from './components/General'
import Opcache from './components/Opcache'

const Settings = () => {
  const {serverId} = useParams()

  return (
    <Routes>
      <Route
        element={
          <div className='card mb-5 mb-xl-10' id='dc_settings_view'>
            <div className='d-flex overflow-auto'>
              <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>Settings</h3>
                </div>
              </div>
            </div>
            <div className='d-flex pt-3'>
              <Tabs
                tabs={[
                  {name: 'General', path: `/server/${serverId}/settings/general`},
                  {name: 'PHP OPcache', path: `/server/${serverId}/settings/opcache`},
                ]}
              />
              <Outlet />
            </div>
          </div>
        }
      >
        <Route path='general' element={<General />} />
        <Route path='opcache' element={<Opcache />} />
        <Route index element={<Navigate to='general' />} />
      </Route>
    </Routes>
  )
}

export default Settings
