// eslint-disable-next-line
import React from 'react'
import {Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import Tabs from '../../../../../utils/Tabs'
import PHPConf from './components/PHPConf'
import PHPWorker from './components/PHPWorker'

const PHPSettings = () => {
  const {applicationId} = useParams()

  return (
    <Routes>
      <Route
        element={
          <div className='card mb-5 mb-xl-10' id='dc_php-settings_view'>
            <div className='d-flex overflow-auto'>
              <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>PHP Settings</h3>
                </div>
              </div>
            </div>
            <div className='d-flex pt-3'>
              <Tabs
                tabs={[
                  {
                    name: 'PHP Conf',
                    path: `/application/${applicationId}/php-settings/conf`,
                  },
                  {
                    name: 'PHP Worker',
                    path: `/application/${applicationId}/php-settings/worker`,
                  },
                ]}
              />
              <Outlet />
            </div>
          </div>
        }
      >
        <Route path='conf' element={<PHPConf />} />
        <Route path='worker' element={<PHPWorker />} />
      </Route>

      <Route index element={<Navigate to='conf' />} />
    </Routes>
  )
}

export default PHPSettings
